import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";
import {Subscription} from "rxjs";
import {TokenStore} from "../modules/auth/models/TokenStore";

@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnDestroy{

  authenticatedOptions: any;
  centralAuth: any;
  unAuthenticated: any;
  uploadOptions: any;
  downloadOptions: any;
  tokenSubscription: Subscription;
  tenantSubscription: Subscription;

  constructor(public http: HttpClient, private auth: AuthService) {

    this.tenantSubscription = this.auth.tenant.currentTenantSubject.subscribe((data: string) => {
      if(data){
        this.refreshHeaders();
      }
    });

    this.tokenSubscription = this.auth.currentToken$.subscribe((data: TokenStore | null) => {
      if(data){
        this.refreshHeaders();
      }
    });
  }

  ngOnDestroy(): void {
    this.tokenSubscription.unsubscribe();
    this.tenantSubscription.unsubscribe();
  }

  refreshHeaders(){

    this.authenticatedOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        "X-Tenant" : this.auth.tenant.currentTenantValue,
        Authorization: 'Bearer ' + this.auth.currentTokenValue.access_token
      }),
    };
    this.centralAuth = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.auth.currentTokenValue.access_token
      }),
    };
    this.downloadOptions = {
      responseType: 'blob',
      headers: new HttpHeaders({
        "X-Tenant" : this.auth.tenant.currentTenantValue,
        Authorization: 'Bearer ' + this.auth.currentTokenValue.access_token
      }),
    };
    this.uploadOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        "X-Tenant" : this.auth.tenant.currentTenantValue,
        Authorization: 'Bearer ' + this.auth.currentTokenValue.access_token
      }),
    };
  }

  getIframeURL(addressType: string, backgroundColor: string, labelColor: string): string{

    return  environment.httpProtocol +
            this.auth.tenant.currentTenantValue +
            environment.tenant_domain + '/enquire?' +
            'addressType=' + addressType +
            '&bgColor=' + backgroundColor +
            '&labelColor=' + labelColor;
  }

  getAPIUrl(): string{

    return  environment.httpProtocol +
            this.auth.tenant.currentTenantValue +
            environment.tenant_domain + '/api/core/leads';
  }

  getSettings(): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/core/settings', this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiGet(url: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/core' + url, this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiGetPublic(url: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/public' + url, this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiCoreGet(url: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api' + url, this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  getPublicInvitation(tenant: string, id: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/join/' + id)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  acceptPublicInvitation(id: string, data: any): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/join/' + id, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiDownload(url: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/core' + url, this.downloadOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  mediaDownload(url: string): Promise<any>{

    console.log('Connecting to Media Server: ' + environment.media_domain);

    //https://media.staging.socialyze.co/tenant-api/core/influencer_search/report/1/pdf

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.media_domain + '/tenant-api/core' + url, this.downloadOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiPost(url: string, data: any, upload: boolean = false): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/core' + url, data, !upload ? this.authenticatedOptions : this.uploadOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiCorePost(url: string, data: any, upload: boolean = false): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api' + url, data, !upload ? this.authenticatedOptions : this.uploadOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiPut(url: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .put(environment.httpProtocol + environment.tenant_domain + '/api/core' + url, {}, this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiDelete(url: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .delete(environment.httpProtocol + environment.tenant_domain + '/api/core' + url, this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  getLoggedInUser(): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/user', this.centralAuth)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  publicPost(url: string, data: any): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/core/' + url,  data,{
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            "X-Tenant" : this.auth.tenant.currentTenantValue
          }),
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  submitTicket(url: string, data: any): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/core/submitTicket', data, this.authenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }



}
